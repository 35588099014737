export const PATHNAMES = {
  HOME: "/",
  CONCERT: "/concert",
  SPORT: "/sport",
  PORTRET: "/portret",
  NATUUR: "/natuur",
  STILLEVEN: "/stilleven",
  ZWARTWIT: "/zwart-wit",
  UITVAART: "/uitvaart",
  CONTACT: "/contact",
};
